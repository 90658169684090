<template>
  <div class="home">
    <div class="item">
      <el-input v-model="type" placeholder="请输入 BehaviorType"></el-input>
    </div>
    <div class="item">
      <el-input v-model="event" placeholder="请输入 Behavior"></el-input>
    </div>
    <div class="item">
      <el-button type="primary" @click="sendData">发送事件</el-button>
    </div>
  </div>
</template>

<script>
import sendBehaviorData from "../../sdk/index.js";
export default {
  name: "Home",
  data() {
    return {
      type: "Customer",
      event: "领取优惠券",
    };
  },
  methods: {
    sendData() {
      sendBehaviorData(this.type, this.event);
    },
  },
};
</script>
<style lang="less" scoped>
.home {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 300px;
  .item {
    margin-bottom: 20px;
  }
}
</style>
