const xhrApi = function (param) {
  let xhr;
  if (window.XMLHttpRequest) {
    xhr = new XMLHttpRequest();
  } else {
    xhr = new ActiveXObject("Microsoft.XMLHTTP");
  }
  xhr.open(param.method || "GET", param.url);
  xhr.setRequestHeader("Content-type", "application/json");
  xhr.onreadystatechange = function () {};
  xhr.send(JSON.stringify(param.data || {}));
};

const parseUrlParam = function () {
  const search = location.search.replace("?", "");
  const map = {};
  search.split("&").forEach((item) => {
    let ls = item.split("=");
    map[ls[0]] = ls[1];
  });
  return map;
};

const formatDate = function (date, fmt = "YYYY-MM-DD HH:mm:ss") {
  if (!date) {
    return date;
  }
  if (typeof date === "string") {
    date = new Date(date);
  }
  if (typeof date === "number") {
    date = new Date(date);
  }
  const o = {
    "M+": date.getMonth() + 1,
    "D+": date.getDate(),
    "h+": date.getHours() % 12 === 0 ? 12 : date.getHours() % 12,
    "H+": date.getHours(),
    "m+": date.getMinutes(),
    "s+": date.getSeconds(),
    "q+": Math.floor((date.getMonth() + 3) / 3),
    S: date.getMilliseconds(),
  };
  const week = {
    0: "\u65e5",
    1: "\u4e00",
    2: "\u4e8c",
    3: "\u4e09",
    4: "\u56db",
    5: "\u4e94",
    6: "\u516d",
  };
  if (/(Y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      `${date.getFullYear()}`.substr(4 - RegExp.$1.length)
    );
  }
  if (/(E+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (RegExp.$1.length > 1
        ? RegExp.$1.length > 2
          ? "\u661f\u671f"
          : "\u5468"
        : "") + week[`${date.getDay()}`]
    );
  }
  for (const k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : `00${o[k]}`.substr(`${o[k]}`.length)
      );
    }
  }
  return fmt;
};

const sendBehaviorData = function (type, behavior) {
  const param = parseUrlParam();
  xhrApi({
    url: "https://madev.yddop.com/datahub/data/ingest",
    method: "POST",
    data: {
      BehaviorType: type,
      Behavior: behavior,
      CustomerId: param.CustomerId,
      CarId: param.CarId,
      CampaignId: param.CampaignId,
      OrgId: param.OrgId,
      CampaignMemberId: param.CampaignMemberId,
      BehaviorTime: formatDate(new Date()),
      MessageChannel: param.MessageChannel,
    },
  });
};

export default sendBehaviorData;
